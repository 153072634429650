import React, { Component, memo, useEffect, useState } from "react";
// import { case2, case3, case4 } from "./imageDimension";
// import LoadingImage from "../resources/images/loading.jpg";
// import NoMatch from "../resources/images/noMatch.png";
// import { HotKeys } from "react-hotkeys";
// import { getImageSrc } from "../utils/files.utils";
// import { apis } from "../utils/apis.utils";

// import { memo, useEffect, useState } from "react";
// import "./LegacyCanvasComponent.scss";
import NoMatch from "../../../assets/images/noMatch.png";
import LoadingImage from "../../../assets/images/loading.jpg";
import { case2, case3, case4 } from "./imageDimensions";
import { HotKeys } from "react-hotkeys";
class LegacyCanvasComponent extends Component {
  canvas = undefined;
  context = undefined;
  canW = undefined;
  canH = undefined;
  dopanning = false;
  scaleFactor = 1.1;
  domarking = false;
  currentMark = undefined;

  panning = {
    start: {
      x: null,
      y: null,
    },
  };
  imgPosX = 0;
  imgPosY = 0;
  lastX;
  lastY;
  img = new Image();
  imgDimensions = undefined;

  promise1 = null;
  promise2 = null;
  showgridLines = true;
  fitToView = true;
  backgroundColor = "#ffffff";
  apis = null;

  constructor(props) {
    super(props);
    this.state = {
      context: undefined,
      currentSrc: "",
    };
    this.getTransformLocal = this.getTransformLocal.bind(this);
    this.draw = this.draw.bind(this);
    this.drawGrid = this.drawGrid.bind(this);
    this.getFileSrc = this.getFileSrc.bind(this);
    this.wrapText = this.wrapText.bind(this);
  }

  imgLoad = () => {
    const { image, setCurrentImageDim, markColor, markings } = this.props;
    const context = this.canvas.getContext("2d");
    // const { markings } = this.props;
    context.setTransform(1, 0, 0, 1, 0, 0);
    this.img.removeEventListener("load", this.imgLoad, false);

    let imgW = this.img.naturalWidth;
    let imgH = this.img.naturalHeight;
    const dims = { imgW, imgH, canW: this.canW, canH: this.canH };
    context.fillStyle = this.backgroundColor;
    context.fillRect(0, 0, this.canW, this.canH);
    if (this.fitToView) {
      if (imgW > this.canW && imgH < this.canH) {
        this.imgDimensions = case2(dims);
      } else if (imgW < this.canW && imgH > this.canH) {
        this.imgDimensions = case4(dims);
      } else if (imgW > this.canW && imgH > this.canH) {
        this.imgDimensions = case3(dims);
      } else {
        this.imgDimensions = {
          width: imgW,
          height: imgH,
          reductionRateW: 0,
          reductionRateH: 0,
        };
      }
    } else {
      this.imgDimensions = {
        width: imgW,
        height: imgH,
        reductionRateW: 0,
        reductionRateH: 0,
      };
    }
    this.imgPosX = Math.round((this.canW - this.imgDimensions.width) / 2);
    this.imgPosY = Math.round((this.canH - this.imgDimensions.height) / 2);
    if (setCurrentImageDim)
      setCurrentImageDim({
        imgId: image._id,
        markPositionOnImgX: 0,
        markPositionOnImgY: 0,
        markWidth:
          this.imgDimensions.width / (1 - this.imgDimensions.reductionRateW),
        markHeight:
          this.imgDimensions.height / (1 - this.imgDimensions.reductionRateH),
      });
    context.drawImage(
      this.img,
      this.imgPosX,
      this.imgPosY,
      this.imgDimensions.width,
      this.imgDimensions.height
    );
    context.fillStyle = markColor;
    if (markings) if (markings.length > 0) this.redrawMarks.bind(this)();
  };
  getFileSrc = async (image) => {
    try {
      // // console.log(this.props.side, 'load log 1', image.awsRetrivePath)
      // if (!image.awsRetrivePath) return;
      // // this.promise1 = await fetch(image.awsRetrivePath);
      // // const res = await this.promise1;
      // // console.log('load log', {side:this.props.side , res})
      // // if (res.type === "cors") {
      // //   const src = await res.text();
      // //   console.log('load log 3', {side:this.props.side , src})

      // //   this.promise2 = await fetch(src);
      // // console.log('load log w', {side:this.props.side , prom2:this.promise2})

      // //   const imageBinary = this.promise2;
      // //   const blob = await imageBinary.blob();
      // if( image.awsRetrivePath){
      //   this.img.src = image.awsRetrivePath;
      // } else {
      //   this.img.src = NoMatch;
      // }
      // this.promise1 = null;
      // this.promise2 = null;
      // this.onload();

      if (!image.awsRetrivePath) return;
      this.promise1 = await fetch(image.awsRetrivePath);

      const res = await this.promise1;

      if (res.type === "cors") {
        const src = await res.text();
        this.promise2 = await fetch(src);
        const imageBinary = this.promise2;
        const blob = await imageBinary.blob();
        this.img.src = URL.createObjectURL(blob);
      } else {
        this.img.src = NoMatch;
      }
      this.promise1 = null;
      this.promise2 = null;
      this.onload();
    } catch (err) {
      //   const src = await getImageSrc({
      //     thumb: `${apis.thumbApiPathforS3}?key=${encodeURIComponent(
      //       image.awsKey
      //     )}`,
      //   });
      this.img.src = image.awsRetrivePath;

      this.onload();
    }
  };

  onload = () => {
    try {
      this.img.addEventListener("load", this.imgLoad, false);
    } catch (err) {
      console.error(err);
    }
  };

  componentDidMount() {
    const { image } = this.props;
    if (image.shouldNotRetrieve) {
      this.img.src = NoMatch;
    } else {
      this.img.src = LoadingImage;
    }
    this.initialize.bind(this)();
  }

  componentWillUnmount() {
    try {
      // console.log(`unmounting`,this.props.image.name)
      this.img.src = "";
      // console.log(this.promise2, this.promise1)
      // if (this.promise1)
      //   if (this.promise1.reject) this.promise1.reject()
      // if (this.promise2)
      //   if (this.promise2.reject) this.promise2.reject()
      const context = this.canvas.getContext("2d");
      if (context)
        if (context.clearRect) context.clearRect(0, 0, this.canW, this.canH);
    } catch (err) {
      console.error(err.message);
    }
  }

  componentDidUpdate = () => {
    const { image, markColor } = this.props;
    if (!this.canvas) return;
    const context = this.canvas.getContext("2d");
    context.strokeStyle = markColor;

    if (image) {
      if (image.shouldNotRetrieve) {
        this.img.src = NoMatch;
        // this.onload();
      } else {
        if (
          (!this.img.src || this.state.currentSrc !== image.awsRetrivePath) &&
          image.awsRetrivePath
        ) {
          this.img.src = LoadingImage;
          this.getFileSrc.bind(this, image)();
          this.setState({
            currentSrc: image.awsRetrivePath,
          });
        } else {
          if (this.fitToView !== this.props.fitToView) {
            this.fitToView = this.props.fitToView;
            this.imgLoad();
          }
        }
      }
    }
    if (this.imgDimensions) {
      this.draw();
    }
  };
  // shouldComponentUpdate = (nextProps) => {
  //   if (this.props.image.fileIndex !== nextProps.image.fileIndex) {
  //     return true; // Re-render if someProp has changed
  //   } else return false;
  // };

  initialize() {
    const { image, canvasContainerId, canvasId, markColor } = this.props;
    if (!image) return;
    const canvCont = document.getElementById(canvasContainerId);
    this.canvas = document.getElementById(canvasId);
    const context = this.canvas.getContext("2d");

    this.canW = Math.round(canvCont.clientWidth);
    this.canH = Math.round(canvCont.clientHeight) - 10;
    this.canvas.setAttribute("width", this.canW);
    this.canvas.setAttribute("height", this.canH);
    context.fillStyle = this.backgroundColor;
    context.fillRect(0, 0, this.canW, this.canH);
    if (image) {
      this.img.src = LoadingImage;
      // console.log({image, curr :this.img.src}, "image  right");
      this.getFileSrc.bind(this, image)();
    } else this.img.src = NoMatch;
    context.strokeStyle = markColor;
    this.canvas.addEventListener("mousedown", this.mousedown, false);
    this.canvas.addEventListener(
      `${
        navigator.userAgent.includes("Chrome") ? "mousewheel" : "DOMMouseScroll"
      }`,
      this.handleScroll,
      false
    );
    this.canvas.addEventListener("mouseup", this.mouseup, false);
    this.setState({
      context: context,
      currentSrc: image ? image.awsRetrivePath : null,
    });
    this.onload();
  }

  // draw() {
  //   const { markings, currentMark, markColor } = this.props;
  //   const context = this.canvas.getContext("2d");
  //   context.save();
  //   context.setTransform(1, 0, 0, 1, 0, 0);
  //   context.fillStyle = this.backgroundColor;
  //   context.fillRect(0, 0, this.canW, this.canH);
  //   // context.clearRect(0, 0, this.canW, this.canH);
  //   context.restore();
  //   console.log( 
  //      this.imgDimensions,
  //     this.imgDimensions.reductionRateW)
  //   try {
  //     context.drawImage(
  //       this.img,
  //       this.imgPosX,
  //       this.imgPosY,
  //       this.imgDimensions.width,
  //       this.imgDimensions.height
  //     );
  //     if (markings) {
  //       if (markings.length > 0) this.redrawMarks();
  //     }
  //     if (currentMark) {
  //       context.fillStyle = currentMark.color || markColor;
  //       context.strokeStyle = currentMark.color || markColor;
  //       let markPositionOnImgX =
  //         currentMark.markPositionOnImgX -
  //         currentMark.markPositionOnImgX * this.imgDimensions.reductionRateW +
  //         this.imgPosX;
  //       let markPositionOnImgY =
  //         currentMark.markPositionOnImgY -
  //         currentMark.markPositionOnImgY * this.imgDimensions.reductionRateH +
  //         this.imgPosY;

  //       let markWidth =
  //         currentMark.markWidth -
  //         currentMark.markWidth * this.imgDimensions.reductionRateW;
  //       let markHeight =
  //         currentMark.markHeight -
  //         currentMark.markHeight * this.imgDimensions.reductionRateH;
  //       if (currentMark.markTool === "rectangle")
  //         this.drawMark(
  //           markPositionOnImgX,
  //           markPositionOnImgY,
  //           markWidth,
  //           markHeight
  //         );
  //       else if (currentMark.markTool === "ellipse") {
  //         let radiusX = markWidth / 2;
  //         let radiusY = markHeight / 2;
  //         let centreX, centreY;
  //         centreX = markPositionOnImgX + radiusX;
  //         centreY = markPositionOnImgY + radiusY;
  //         context.beginPath();
  //         context.ellipse(
  //           centreX,
  //           centreY,
  //           Math.abs(radiusX),
  //           Math.abs(radiusY),
  //           2 * Math.PI,
  //           0,
  //           2 * Math.PI
  //         );
  //         context.stroke();
  //       } else if (currentMark.markTool === "freehand") {
  //         this.freeHandDraw(currentMark.fhdArr);
  //       }
  //     }
  //     this.drawGrid();
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }
draw() {
    const { markings, currentMark, markColor, drawBorderOnImage } = this.props;
    const context = this.canvas.getContext("2d");
    context.save();
    context.setTransform(1, 0, 0, 1, 0, 0);

    // Clear the canvas and set the background color
    context.fillStyle = this.backgroundColor;
    context.fillRect(0, 0, this.canW, this.canH);
    context.restore();

    try {
      // Draw the image
      context.drawImage(
        this.img,
        this.imgPosX,
        this.imgPosY,
        this.imgDimensions.width,
        this.imgDimensions.height
      );

      // Draw border and checkered background outside the image ONLY if borderOnImage is true
      if (drawBorderOnImage) {
        const checkerSize = 10; // Size of each checkered square
        const color1 = "#f0f0f0"; // Light gray
        const color2 = "#0000ff22"; // White

        // Function to draw checkered pattern in a given area
        const drawCheckeredBackground = (x, y, width, height) => {
          for (let i = 0; i < width; i += checkerSize) {
            for (let j = 0; j < height; j += checkerSize) {
              context.fillStyle = (i + j) % (checkerSize * 2) === 0 ? color1 : color2;
              context.fillRect(x + i, y + j, checkerSize, checkerSize);
            }
          }
        };

        // Draw checkered background in the areas outside the image
        // Top rectangle
        drawCheckeredBackground(0, this.imgPosY% checkerSize, this.canW, parseInt(this.imgPosY/checkerSize)*checkerSize);
        // Bottom rectangle
        drawCheckeredBackground(0, this.imgPosY + this.imgDimensions.height, this.canW, this.canH - (this.imgPosY + this.imgDimensions.height));
        // Left rectangle
        drawCheckeredBackground((this.imgPosX %checkerSize), this.imgPosY, parseInt(this.imgPosX /checkerSize)*checkerSize, this.imgDimensions.height);
        // drawCheckeredBackground(0, 0, (this.imgPosX %20), this.imgDimensions.height);

        // Right rectangle
        drawCheckeredBackground(this.imgPosX + this.imgDimensions.width, this.imgPosY, this.canW - (this.imgPosX + this.imgDimensions.width), this.imgDimensions.height);

        // Draw a border around the image
        // context.strokeStyle = "#00000011";
        // context.lineWidth = 1; // Border width
        // context.strokeRect(
        //   this.imgPosX,
        //   this.imgPosY,
        //   this.imgDimensions.width,
        //   this.imgDimensions.height
        // );
      }

      // Draw markings if any
      if (markings && markings.length > 0) {
        this.redrawMarks();
      }

      // Draw the current mark if any
      if (currentMark) {
        context.fillStyle = currentMark.color || markColor;
        context.strokeStyle = currentMark.color || markColor;
        let markPositionOnImgX =
          currentMark.markPositionOnImgX -
          currentMark.markPositionOnImgX * this.imgDimensions.reductionRateW +
          this.imgPosX;
        let markPositionOnImgY =
          currentMark.markPositionOnImgY -
          currentMark.markPositionOnImgY * this.imgDimensions.reductionRateH +
          this.imgPosY;

        let markWidth =
          currentMark.markWidth -
          currentMark.markWidth * this.imgDimensions.reductionRateW;
        let markHeight =
          currentMark.markHeight -
          currentMark.markHeight * this.imgDimensions.reductionRateH;

        if (currentMark.markTool === "rectangle") {
          this.drawMark(
            markPositionOnImgX,
            markPositionOnImgY,
            markWidth,
            markHeight
          );
        } else if (currentMark.markTool === "ellipse") {
          let radiusX = markWidth / 2;
          let radiusY = markHeight / 2;
          let centreX = markPositionOnImgX + radiusX;
          let centreY = markPositionOnImgY + radiusY;
          context.beginPath();
          context.ellipse(
            centreX,
            centreY,
            Math.abs(radiusX),
            Math.abs(radiusY),
            2 * Math.PI,
            0,
            2 * Math.PI
          );
          context.stroke();
        } else if (currentMark.markTool === "freehand") {
          this.freeHandDraw(currentMark.fhdArr);
        }
      }

      this.drawGrid();
    } catch (err) {
      console.error(err);
    }
  }
  drawMark = (startX, startY, markWidth, markHeight) => {
    const context = this.canvas.getContext("2d");
    context.strokeRect(startX, startY, markWidth, markHeight);
  };

  drawGrid() {
    let ctx = this.canvas.getContext("2d");
    ctx.save();
    ctx.strokeStyle = "#e07b39";
    ctx.lineWidth = 2;
    let imgW = this.img.naturalWidth;
    let imgH = this.img.naturalHeight;
    // console.log(this.getTransformLocal())

    if (this.props.gridData && this.props.gridData.top) {
      for (let n = 0; n < this.props.gridData.top.length; n++) {
        ctx.beginPath();
        const temp = this.props.gridData.top[n];
        const point = this.fitToView
          ? temp - temp * this.imgDimensions.reductionRateH + this.imgPosY
          : temp + this.imgPosY;
        ctx.moveTo(this.fitToView ? 0 : this.imgPosX, point);
        ctx.lineTo(this.fitToView ? this.canW : imgW, point);
        ctx.stroke();
      }
    }

    if (this.props.gridData && this.props.gridData.bottom) {
      for (let n = 0; n < this.props.gridData.bottom.length; n++) {
        ctx.beginPath();
        const temp = imgH - this.props.gridData.bottom[n];
        const point = this.fitToView
          ? temp - temp * this.imgDimensions.reductionRateH + this.imgPosY
          : temp + this.imgPosY;
        ctx.moveTo(this.fitToView ? 0 : this.imgPosX, point);
        ctx.lineTo(this.fitToView ? this.canW : imgW, point);
        ctx.stroke();
      }
    }

    if (this.props.gridData && this.props.gridData.left) {
      for (let n = 0; n < this.props.gridData.left.length; n++) {
        ctx.beginPath();
        const temp = this.props.gridData.left[n];
        const point = this.fitToView
          ? temp - temp * this.imgDimensions.reductionRateW + this.imgPosX
          : temp + this.imgPosX;
        ctx.moveTo(point, this.fitToView ? 0 : this.imgPosY);
        ctx.lineTo(point, this.fitToView ? this.canH : imgH);
        ctx.stroke();
      }
    }

    if (this.props.gridData && this.props.gridData.right) {
      for (let n = 0; n < this.props.gridData.right.length; n++) {
        const temp = imgW - this.props.gridData.right[n];
        const point = this.fitToView
          ? temp - temp * this.imgDimensions.reductionRateW + this.imgPosX
          : temp + this.imgPosX;
        ctx.beginPath();
        ctx.moveTo(point, this.fitToView ? 0 : this.imgPosY);
        ctx.lineTo(point, this.fitToView ? this.canH : imgH);
        ctx.stroke();
      }
    }
    ctx.restore();
  }

  redrawMarks() {
    const { selected, markings, image, markColor, ignoreCommentCheck } =
      this.props;
    const context = this.canvas.getContext("2d");
    let transforms = this.getTransformLocal();
    let scale = { x: transforms.a, y: transforms.d };
    if (!markings) return;

    var maxWidth = 250;
    var lineHeight = 25;
    // var x = (this.canvas.width - maxWidth) / 2;
    // var y = 60;
    // var text = 'All the world \'s a stage, and all the men and women merely players. They have their exits and their entrances; And one man in his time plays many parts.';

    // context.font = '16pt Calibri';
    // context.fillStyle = '#333';

    for (let i = 0; i < markings.length; i++) {
      let mark = {
        ...markings[i],
        fileId: markings[i]._id,
      };

      // if (mark.fileId !== image._id) return;
      if (!mark.toggleOn && !ignoreCommentCheck) {
        continue;
      }
      // console.log('for in marks', mark)
      context.lineWidth = 5 / scale.x;
      context.strokeStyle = mark.color || markColor;
      context.fillStyle = mark.color || markColor;

      if (mark._id && selected && mark._id === selected) {
        context.strokeStyle = "#00ff00";
      }

      let markPositionOnImgX =
        mark.markPositionOnImgX -
        mark.markPositionOnImgX * this.imgDimensions.reductionRateW +
        this.imgPosX;
      let markPositionOnImgY =
        mark.markPositionOnImgY -
        mark.markPositionOnImgY * this.imgDimensions.reductionRateH +
        this.imgPosY;

      let markWidth =
        mark.markWidth - mark.markWidth * this.imgDimensions.reductionRateW;
      let markHeight =
        mark.markHeight - mark.markHeight * this.imgDimensions.reductionRateH;

      if (mark.markType === "ellipse") {
        let radiusX = markWidth / 2;
        let radiusY = markHeight / 2;
        let centreX, centreY;
        centreX = markPositionOnImgX + radiusX;
        centreY = markPositionOnImgY + radiusY;
        context.beginPath();
        context.ellipse(
          centreX,
          centreY,
          Math.abs(radiusX),
          Math.abs(radiusY),
          2 * Math.PI,
          0,
          2 * Math.PI
        );
        context.stroke();
        // context.fillText(mark.error, centreX - radiusX + 10, centreY);
        this.wrapText(
          context,
          mark.error,
          centreX - radiusX + 10,
          centreY,
          maxWidth,
          lineHeight
        );
      } else if (mark.markType === "freehand") {
        this.freeHandDraw(mark.freeHandArray);
        let positionX =
          mark.freeHandArray[0].positionX -
          mark.freeHandArray[0].positionX * this.imgDimensions.reductionRateW +
          this.imgPosX;
        let positionY =
          mark.freeHandArray[0].positionY -
          mark.freeHandArray[0].positionY * this.imgDimensions.reductionRateH +
          this.imgPosY;
        context.fillStyle = mark.color;
        // context.fillText(mark.error, positionX + 10, positionY + 10);
        this.wrapText(
          context,
          mark.error,
          positionX + 10,
          positionY + 10,
          maxWidth,
          lineHeight
        );
      } else {
        context.strokeRect(
          markPositionOnImgX,
          markPositionOnImgY,
          markWidth,
          markHeight
        );
        // context.fillText(mark.error, markPositionOnImgX + 5, markPositionOnImgY + 10);
        this.wrapText(
          context,
          mark.error,
          markPositionOnImgX + 5,
          markPositionOnImgY + 10,
          maxWidth,
          lineHeight
        );
      }
    }
    // context.restore()
    this.drawGrid();
  }

  wrapText(context, text, x, y, maxWidth, lineHeight) {
    var words = text.split(" ");
    var line = "";

    for (var n = 0; n < words.length; n++) {
      var testLine = line + words[n] + " ";
      var metrics = context.measureText(testLine);
      var testWidth = metrics.width;
      if (testWidth > maxWidth && n > 0) {
        context.fillText(line, x, y);
        line = words[n] + " ";
        y += lineHeight;
      } else {
        line = testLine;
      }
    }
    context.fillText(line, x, y);
  }

  freeHandDraw = (freeHandDrawingArr = this.props.freeHandDrawingArr) => {
    const context = this.canvas.getContext("2d");
    context.lineJoin = "round";
    for (let i = 0; i < freeHandDrawingArr.length; i++) {
      let fhd = freeHandDrawingArr[i];
      let pfhd = freeHandDrawingArr[i - 1];

      let positionX =
        fhd.positionX -
        fhd.positionX * this.imgDimensions.reductionRateW +
        this.imgPosX;
      let positionY =
        fhd.positionY -
        fhd.positionY * this.imgDimensions.reductionRateH +
        this.imgPosY;

      context.beginPath();
      if (fhd.isOnMove && pfhd) {
        let positionX2 =
          pfhd.positionX -
          pfhd.positionX * this.imgDimensions.reductionRateW +
          this.imgPosX;
        let positionY2 =
          pfhd.positionY -
          pfhd.positionY * this.imgDimensions.reductionRateH +
          this.imgPosY;
        context.moveTo(positionX2, positionY2);
      } else {
        context.moveTo(positionX - 1, positionY);
      }
      context.lineTo(positionX, positionY);
      context.closePath();
      context.stroke();
    }
    context.lineJoin = "miter";
  };

  getTransformLocal = () => {
    const context = this.canvas.getContext("2d");

    if (navigator.userAgent.includes("Chrome")) return context.getTransform();
    else if (navigator.userAgent.includes("Firefox")) {
      return {
        a: context.mozCurrentTransform[0],
        b: context.mozCurrentTransform[1],
        c: context.mozCurrentTransform[2],
        d: context.mozCurrentTransform[3],
        e: context.mozCurrentTransform[4],
        f: context.mozCurrentTransform[5],
      };
    }
    return context.mozCurrentTransform;
  };

  mousedown = (e) => {
    try {
      const { setFreeHandDrawingArr, markTool } = this.props;

      if (this.props.addingQuote) return;
      /* this removes the right click menu */
      window.oncontextmenu = function () {
        return false;
      };
      let trans = this.getTransformLocal();

      document.body.style.mozUserSelect =
        document.body.style.webkitUserSelect =
        document.body.style.userSelect =
          "none";
      if (e.type === "touchstart") {
        // document.body.onscroll((evt) => evt.preventDefault())
        document.addEventListener("scroll", this.noScroll);
        let boundingClient = e.target.getBoundingClientRect();
        let bodyRect = document.body.getBoundingClientRect();

        this.panning.start.x =
          e.changedTouches[0].pageX - (boundingClient.left - bodyRect.left);
        this.panning.start.y =
          e.changedTouches[0].pageY - (boundingClient.top - bodyRect.top);
      } else {
        this.panning.start.x = e.offsetX;
        this.panning.start.y = e.offsetY;
        let pointX = e.offsetX / trans.a - trans.e / trans.a;
        let pointY = e.offsetY / trans.d - trans.f / trans.d;
        pointX -= this.imgPosX;
        pointY -= this.imgPosY;
        pointX /= 1 - this.imgDimensions.reductionRateW;
        pointY /= 1 - this.imgDimensions.reductionRateH;
        setFreeHandDrawingArr(parseInt(pointX, 10), parseInt(pointY, 10));
      }

      if (markTool !== 0) {
        this.canvas.addEventListener("mousemove", this.liveMarking, false); // this event shows the user where it is drawing rectangle
        this.canvas.addEventListener("touchmove", this.liveMarking, false);
        return;
      }

      this.canvas.addEventListener("mousemove", this.mousemove, false);
      this.canvas.addEventListener("touchmove", this.mousemove, false);
    } catch (err) {
      console.error(err);
    }
  };

  liveMarking = (e) => {
    try {
      const { markTool, setFreeHandDrawingArr, markColor } = this.props;
      const context = this.canvas.getContext("2d");
      context.fillStyle = markColor;
      this.draw();
      let trans = this.getTransformLocal();
      context.lineWidth = 5 / trans.a;
      let startX = this.panning.start.x / trans.a - trans.e / trans.a;
      // console.log((panning.start.x / trans.a))
      let startY = this.panning.start.y / trans.d - trans.f / trans.d;
      if (e.type === "touchmove") {
        let boundingClient = e.target.getBoundingClientRect();
        let bodyRect = document.body.getBoundingClientRect();
        let offsetX =
          e.changedTouches[0].pageX - (boundingClient.left - bodyRect.left);
        let offsetY =
          e.changedTouches[0].pageY - (boundingClient.top - bodyRect.top);
        if (markTool === 1)
          context.strokeRect(
            startX,
            startY,
            (offsetX - this.panning.start.x) / trans.a,
            (offsetY - this.panning.start.y) / trans.d
          );
        else if (markTool === 2) {
          let endX = offsetX / trans.a - trans.e / trans.a;
          let endY = offsetY / trans.d - trans.f / trans.d;
          let radiusX = (endX - startX) / 2;
          let radiusY = (endY - startY) / 2;
          let centreX, centreY;
          centreX = startX + radiusX;
          centreY = startY + radiusY;
          context.beginPath();
          context.ellipse(
            centreX,
            centreY,
            Math.abs(radiusX),
            Math.abs(radiusY),
            2 * Math.PI,
            0,
            2 * Math.PI
          );
          context.stroke();
        }
      } else {
        if (markTool === 1) {
          context.strokeStyle = markColor;
          context.strokeRect(
            startX,
            startY,
            (e.offsetX - this.panning.start.x) / trans.a,
            (e.offsetY - this.panning.start.y) / trans.d
          );
        } else if (markTool === 2) {
          let endX = e.offsetX / trans.a - trans.e / trans.a;
          let endY = e.offsetY / trans.d - trans.f / trans.d;
          let radiusX = (endX - startX) / 2;
          let radiusY = (endY - startY) / 2;
          let centreX, centreY;
          centreX = startX + radiusX;
          centreY = startY + radiusY;
          context.beginPath();
          context.ellipse(
            centreX,
            centreY,
            Math.abs(radiusX),
            Math.abs(radiusY),
            2 * Math.PI,
            0,
            2 * Math.PI
          );
          context.stroke();
        } else if (markTool === 3) {
          let pointX = e.offsetX / trans.a - trans.e / trans.a;
          let pointY = e.offsetY / trans.d - trans.f / trans.d;
          pointX -= this.imgPosX;
          pointY -= this.imgPosY;
          pointX /= 1 - this.imgDimensions.reductionRateW;
          pointY /= 1 - this.imgDimensions.reductionRateH;

          setFreeHandDrawingArr(
            parseInt(pointX, 10),
            parseInt(pointY, 10),
            true
          );
          this.freeHandDraw();
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  mouseup = (e) => {
    try {
      if (this.props.addingQuote) return;

      if (e.type === "touchend")
        document.removeEventListener("scroll", this.noScroll);

      if (this.props.markTool !== 0) {
        if (e.type === "touchend") {
          let boundingClient = e.target.getBoundingClientRect();
          let bodyRect = document.body.getBoundingClientRect();
          let offsetX =
            e.changedTouches[0].pageX - (boundingClient.left - bodyRect.left);
          let offsetY =
            e.changedTouches[0].pageY - (boundingClient.top - bodyRect.top);
          this.storeMarking(offsetX, offsetY); // this function takes x and y coordinates of the endpoint of a marking to store
        } else this.storeMarking(e.offsetX, e.offsetY); // this function takes x and y coordinates of the endpoint of a marking to store

        this.draw();
        // this.takeInput(e); // it appends a form in the canvas to get review
        this.canvas.removeEventListener("mousemove", this.liveMarking, false);
        this.canvas.removeEventListener("touchmove", this.liveMarking, false);
        return;
      }
      this.canvas.removeEventListener("mousemove", this.mousemove, false);
      this.canvas.removeEventListener("touchmove", this.mousemove, false);
      this.canvas.removeEventListener("mousemove", this.liveMarking, false);
      this.canvas.removeEventListener("touchmove", this.liveMarking, false);
      this.panning.start.x = null;
      this.panning.start.y = null;
      this.draw();
    } catch (err) {
      console.error(err);
    }
  };

  storeMarking = (endX, endY) => {
    try {
      const { setCurrentMark, markTool } = this.props;
      let startX = this.panning.start.x;
      let startY = this.panning.start.y;
      let transforms = this.getTransformLocal();
      let translate = { x: transforms.e, y: transforms.f };
      let scale = { x: transforms.a, y: transforms.d };
      let canvasValueX = startX / scale.x - translate.x / scale.x;
      let canvasValueY = startY / scale.y - translate.y / scale.y;
      let markWidth = (endX - startX) / scale.x;
      let markHeight = (endY - startY) / scale.y;
      let markPositionOnImgX = canvasValueX - this.imgPosX;
      let markPositionOnImgY = canvasValueY - this.imgPosY;
      let markToolValue =
        markTool === 1 ? "rectangle" : markTool === 2 ? "ellipse" : "freehand";

      if (markTool !== 3)
        setCurrentMark({
          imgId: this.props.image._id,
          startX: canvasValueX,
          startY: canvasValueY,
          markWidth: markWidth / (1 - this.imgDimensions.reductionRateW),
          markHeight: markHeight / (1 - this.imgDimensions.reductionRateH),
          markPositionOnImgX:
            markPositionOnImgX / (1 - this.imgDimensions.reductionRateW), // to get the distance of marking from image origin, simply subtract canvasValueX variable value from image's initial origin
          markPositionOnImgY:
            markPositionOnImgY / (1 - this.imgDimensions.reductionRateH),
          markTool: markToolValue,
        });
      else
        setCurrentMark({
          imgId: this.props.image._id,
          fhdArr: this.props.freeHandDrawingArr,
          markTool: markToolValue,
        });
    } catch (err) {
      console.error(err);
    }
  };

  mousemove = (e) => {
    try {
      const context = this.canvas.getContext("2d");

      let trans = this.getTransformLocal();
      context.translate(
        e.offsetX / trans.a - this.panning.start.x / trans.a,
        e.offsetY / trans.d - this.panning.start.y / trans.d
      );
      this.panning.start.x = e.offsetX;
      this.panning.start.y = e.offsetY;
      this.draw();
    } catch (err) {
      console.error(err);
    }
  };

  zoom = (delta) => {
    try {
      const context = this.canvas.getContext("2d");

      if (!this.dopanning) {
        let transforms = this.getTransformLocal();
        let scale = { x: transforms.a, y: transforms.d };
        let translate = { x: transforms.e, y: transforms.f };
        let newLastX = this.lastX / scale.x - translate.x / scale.x,
          newLastY = this.lastY / scale.y - translate.y / scale.y;

        context.translate(newLastX, newLastY);
        let totalScaling = Math.pow(this.scaleFactor, delta);

        if (scale.x <= 1 || scale.y <= 1) {
          if (totalScaling > 1) {
            context.scale(totalScaling, totalScaling);
          }
        } else {
          // if (scale.x >= 3 || scale.y >= 3) {
          // if (totalScaling < 1) {
          // context.scale(totalScaling, totalScaling);
          // }
          // } else {

          // }
          context.scale(totalScaling, totalScaling);
        }
        context.translate(-newLastX, -newLastY);
        this.draw();
      }
    } catch (err) {
      console.error(err);
    }
  };

  handleScroll = (evt) => {
    try {
      if (this.props.addingQuote) return;
      this.lastX = evt.offsetX;
      this.lastY = evt.offsetY;
      var delta = evt.wheelDelta
        ? evt.wheelDelta / 40
        : evt.detail
        ? -evt.detail
        : 0;
      if (delta) this.zoom(delta);
      return evt.preventDefault() && false;
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    const { setFitToView, side } = this.props;
    const keyMap = {
      ZOOM_IN: { name: "Zoom In", sequence: "z" },
      ZOOM_OUT: { name: "Zoom Out", sequence: "shift+z" },

      FIT_TO_VIEW: { name: "Fit to view", sequence: "f" },
      HUNDRED_PERCENT_ZOOM: { name: "100% Zoom", sequence: "o" },

      ROTATE_RIGHT: { name: "Rotate Image Clockwise", sequence: "r" },
      ROTATE_LEFT: { name: "Rotate Image Anti Clockwise", sequence: "shift+r" },

      TOGGLE_GRID_LINES: {
        name: "Toggle gridlines for current image only",
        sequence: "d",
      },

      TOGGLE_GRID_LINES_PERMANENT: {
        name: "Toggle gridlines for current image only",
        sequence: "shift+d",
      },

      TOGGLE_COMMENTS_MARKING: {
        name: "Toggle comment and markings on the rejected image",
        sequence: "x",
      },
    };

    function setFitToViewLocal() {
      setFitToView(side, true);
    }
    function setFullSize() {
      setFitToView(side, false);
    }
    const handlers = {
      ZOOM_IN: this.zoom.bind(this, 1.35),
      ZOOM_OUT: this.zoom.bind(this, -1.35),
      // ROTATE_RIGHT: this.rotate.bind(this, 90),
      // ROTATE_LEFT: this.rotate.bind(this, -90),
      FIT_TO_VIEW: setFitToViewLocal,
      HUNDRED_PERCENT_ZOOM: setFullSize,
      // HUNDRED_PERCENT_ZOOM: this.hundred_percent_zoom.bind(this),
      // TOGGLE_GRID_LINES: this.toggle_grid_lines.bind(this),
      // TOGGLE_COMMENTS_MARKING: this.toggle_comments_marking.bind(this),
    };
    return (
      <HotKeys keyMap={keyMap} handlers={handlers}>
        <canvas id={this.props.canvasId}></canvas>
      </HotKeys>
    );
  }
}

LegacyCanvasComponent.defaultProps = { ignoreCommentCheck: false };
export default LegacyCanvasComponent;
