import React, { Component } from "react";
// import CanvasComponent from "../../../Editor/CanvasComponent";
// import { apis } from "../../../utils/apis.utils";
// import { getImageMarkings } from "../../../utils/files.utils";
// import AnnotationTools from "./AnnotationTools";
// import CanvasActions from "./CanvasActions";
import "./preview.style.css";
import LegacyCanvasComponent from "../CanvasComponent/LegacyCanvasComponent";
import AnnotationTools from "../AnnotationTools/AnnotationTools";
import CanvasActions from "../CanvasActions/CanvasActions";
import { tempUrl } from "../../../Config";
import axios from "axios";
const getImageMarkings = () => {};
const apis = "";
class InstanceLegacy extends Component {
  state = {
    markTool: 0,
    left: this.props.left,
    right: this.props.right,
    leftMarkings: [],
    rightMarkings: [],
    currentMark: null,
    freeHandDrawingArr: [],
    addingQuote: false,
    commentOn: false,
    currentImageDim: {},
    selected: undefined,
    fitToView: {
      left: true,
      right: true,
    },
    leftGridData: localStorage.getItem("leftGrid")
      ? JSON.parse(localStorage.getItem("leftGrid"))
      : {
          top: [],
          right: [],
          bottom: [],
          left: [],
        },
    rightGridData: localStorage.getItem("rightGrid")
      ? JSON.parse(localStorage.getItem("rightGrid"))
      : {
          top: [],
          right: [],
          bottom: [],
          left: [],
        },
    rangeValue: 1,
    maxOffset: 0,
    isDragging: false,
    initialY: 0,
    initialOffsetY: 0,
  };

  constructor(props) {
    super(props);
    this.initialize = this.initialize.bind(this);
    this.updateSlider = this.updateSlider.bind(this);
    this.dragDiv = this.dragDiv.bind(this);
  }
  setFitToView(side, value) {
    this.setState((state) => {
      const updatedFitToView = {
        ...state.fitToView, // Create a shallow copy of the existing state
        [side]: value !== undefined ? value : !state.fitToView[side],
      };
      return { fitToView: updatedFitToView }; // Return the new state object
    });
  }

  setCurrentImageDim(dim) {
    this.setState({
      currentImageDim: {
        imgId: dim.imgId,
        height: dim.markHeight,
        markWidth: dim.markWidth,
        markHeight: dim.markHeight,
        markPositionOnImgX: 0,
        markPositionOnImgY: 0,
      },
    });
  }

  setMarkTool(value) {
    if (this.props.restrictComments) {
      this.props.handleSnacks({
        message: "you have to reject image to add comments.",
        variant: "info",
      });
      return;
    }
    this.setState({
      markTool: value,
    });
    this.props.addHorizontalLine(value);
    this.toggleComment(false);
  }

  async updateSlider(e) {
    await this.setState({ rangeValue: e.target.value });
  }

  async dragDiv(e, divNumber) {
    const { maxOffset, isDragging, initialY } = this.state;
    e = e || window.event;
    let dragY = e.pageY;

    if (isNaN(dragY) || dragY === 0) {
      return;
    }
    e.preventDefault();

    // Start dragging
    if (!isDragging) {
      this.setState({ isDragging: true, initialY: dragY });
    }

    // Calculate yOffset based on the difference between current dragY and initialY
    let yOffset = Math.min(Math.max(0, dragY - initialY), maxOffset - 68);
    if (yOffset == maxOffset - 68) {
      yOffset -= 10;
    }
    switch (divNumber) {
      case 1:
        document.getElementsByClassName("divtest2")[0].style.top =
          yOffset + "px";
        break;
      case 2:
        document.getElementsByClassName("divtest3")[0].style.top =
          yOffset + "px";
        break;
      case 3:
        document.getElementsByClassName("divtest4")[0].style.top =
          yOffset + "px";
        break;
      default:
        break;
    }
  }

  async initialize() {
    const { left, right, projectId } = this.props;
    try {
      // let leftMarkingsPromise;
      // if (!left.shouldNotRetrieve)
      //   if (!left.version)
      //     leftMarkingsPromise = getImageMarkings({
      //       projectId,
      //       imageId: left._id,
      //     });
      //   else
      //     leftMarkingsPromise = getImageMarkings({
      //       projectId,
      //       imageId: left._id,
      //       rv: left.version,
      //     });
      // else
      //   leftMarkingsPromise = new Promise((resolve) =>
      //     resolve({ markings: [] })
      //   );
      const rightMarkingsPromise = getImageMarkings({
        projectId,
        imageId: right._id,
      });
      this.setState({
        leftMarkings:
          this?.props?.leftData?.latestMarkings ??
          this?.props?.leftData?.markings,
        rightMarkings: this?.props?.markingList.map((elm) => {
          return { ...elm, toggleOn: true };
        }),
      });
      this.props.updateCommentLength(this?.props?.markingList, "187");

      // Promise.all([leftMarkingsPromise, rightMarkingsPromise])
      //   .then(([leftMarkings, rightMarkings]) => {
      //     rightMarkings.markings.map((e) => {
      //       e.markings.toggleOn = true;
      //     });
      //     this.setState({
      //       leftMarkings: leftMarkings.markings,
      //       rightMarkings: this?.props?.rightData?.latestMarkings,
      //     });

      //     this.props.updateCommentLength(rightMarkings.markings, "187");
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //   });
      // left.awsRetrivePath = `${apis.thumbApiPath}?key=${encodeURIComponent(
      //   left.awsKey
      // )}`;
      // right.awsRetrivePath = `${apis.thumbApiPath}?key=${encodeURIComponent(
      //   right.awsKey
      // )}`;
      left.loading = false;
      right.loading = false;
      // this.setState({
      //   left,
      //   // right,
      // });
    } catch (err) {
      console.error(err);
    }
  }

  //function copy
  // async initialize (){
  //     const { left:leftProps, right:rightProps, projectId } = this.props;
  //     try {
  //         let leftMarkingsPromise;
  //         if (!leftProps?.shouldNotRetrieve)
  //             if (!leftProps?.version)
  //                 leftMarkingsPromise = getImageMarkings({
  //                     projectId,
  //                     imageId: leftProps?._id,
  //                 });
  //             else
  //                 leftMarkingsPromise = getImageMarkings({
  //                     projectId,
  //                     imageId: leftProps?._id,
  //                     rv: leftProps?.version,
  //                 });
  //         else
  //             leftMarkingsPromise = new Promise((resolve) =>
  //                 resolve({ markings: [] })
  //             );
  //         const rightMarkingsPromise = getImageMarkings({
  //             projectId,
  //             imageId: rightProps?._id,
  //         });

  //         Promise.all([leftMarkingsPromise, rightMarkingsPromise])
  //             .then(([leftMarkings, rightMarkings]) => {
  //                 // rightMarkings?.markings.map((e) => {
  //                 // 	e.markings.toggleOn = true;
  //                 // });

  //                 setLeftMarking(leftMarkings?.markings);
  //                 setRightmarkings(rightMarkings?.markings);
  //                 updateCommentLength(rightMarkings?.markings, "187");
  //             })
  //             .catch((err) => {
  //                 console.error(err);
  //             });
  //         // leftProps.awsRetrivePath = `${
  //         // 	apis.thumbApiPath
  //         // }?key=${encodeURIComponent(leftProps.awsKey)}`;
  //         // rightProps.awsRetrivePath = `${
  //         // 	apis.thumbApiPath
  //         // }?key=${encodeURIComponent(rightProps.awsKey)}`;
  //         // leftProps.loading = false;
  //         // rightProps.loading = false;
  //         // setLeft(leftProps);
  //         // setRight(rightProps);
  //     } catch (err) {
  //         console.error(err);
  //     }
  // };

  componentDidMount() {
    this.initialize();
    this.setState({
      maxOffset:
        document.getElementsByClassName("divtest1")[0].offsetHeight + 68,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.state.left.path !== this.props.left.path) this.initialize();
    // console.log('prorrrr', {flag: this.state.rightMarkings.length != this.props.markingList, right:this.state.rightMarkings.length, list:this.props.markingList} )
    // if(this.state.rightMarkings.length != this.props.markingList.length) this.initialize();
    if (prevProps.left.fileIndex !== this.props.left.fileIndex) {
      // Update the state with the new left prop
      this.setState({ left: { ...this.props.left } });
    }
    if (
      JSON.stringify(prevProps.markingList) !==
      JSON.stringify(this.props.markingList)
    ) {
      this.setState({
        rightMarkings: this?.props?.markingList.map((elm) => {
          return { ...elm, toggleOn: true };
        }),
      });
      this.props.updateCommentLength(this?.props?.markingList, "187");
    }
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   // You can add additional conditions here for other props or state changes
  //   if (
  //     this.props.left.fileIndex !== nextProps.left.fileIndex ||
  //     this.state.left !== nextState.left
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  updateLeftGridData(data) {
    localStorage.setItem("leftGrid", JSON.stringify(data));
    this.setState({
      leftGridData: data,
    });
  }

  updateRightGridData(data) {
    localStorage.setItem("rightGrid", JSON.stringify(data));
    this.setState({
      rightGridData: data,
    });
  }

  setCurrentMark = (marking) => {
    marking.active = true;
    let markState = {
      currentMark: marking,
      addingQuote: this.state.markTool === 3 ? false : true,
      commentOn: true,
    };
    this.setState({ ...markState });
    this.props.getMarkState(marking);
  };
  // shouldComponentUpdate = (nextProps) => {
  //   if (this.props.left.fileIndex !== nextProps.left.fileIndex) {
  //     return true;
  //   } else return false;
  // };

  // setFreeHandDrawingArr = (positionX, positionY, isOnMove) => {
  //   let fhdArr = this.state.freeHandDrawingArr;
  //   fhdArr.push({ positionX, positionY, isOnMove });
  //   this.setState({
  //     freeHandDrawingArr: fhdArr,
  //   });
  // };
  setFreeHandDrawingArr = (positionX, positionY, isOnMove) => {
    this.setState((prevState) => {
      const updatedFhdArr = [
        ...prevState.freeHandDrawingArr,
        { positionX, positionY, isOnMove },
      ];
      return {
        freeHandDrawingArr: updatedFhdArr,
      };
    });
  };

  sendMark(mark) {
    try {
      this.setState((state) => {
        return {
          commentOn: false,
          currentMark: undefined,
          lastMark: undefined,
          addingQuote: false,
          freeHandDrawingArr: [],
        };
      });
      this.saveMark(mark);
    } catch (err) {
      console.error(err);
    }
  }

  async saveMark(mark) {
    const { right, projectId } = this.props;
    const token = localStorage.getItem("token");
    let newMarkingList = [...this.props.markingList, mark];
    let newMark = { ...mark };
    delete newMark.imgId; //find a better approach

    delete newMark.markTool;
    delete newMark.active;
    delete newMark.new;
    delete newMark.startX;
    delete newMark.startY;
    newMark["markType"] = mark.markTool;

    if (mark.markTool == "freehand") {
      newMark["freeHandArray"] = mark.fhdArr;
    }

    try {
      axios
        .put(
          `${tempUrl}/files/saveMarkings`,
          {
            marking: newMark,
            fileId: projectId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.setState({
            saveMarkings: res.data,
            isLoading: true,
            mainReason: "",
            subReason: "",
            error: "",
          });

          this.props.updateCommentLength(
            res?.data?.file?.latestMarkings,
            "307"
          );
        });
      // this.setState(
      //   {
      //     saveMarkings: responseObject.data,
      //     isLoading: true,
      //     mainReason: "",
      //     subReason: "",
      //     error: "",
      //   },
      //   () => {
      //     getImageMarkings({ projectId, imageId: right._id })
      //       .then((rightMarkings) => {
      //         rightMarkings.markings.map((e) => {
      //           e.markings.toggleOn = true;
      //         });

      //         this.setState({
      //           rightMarkings: rightMarkings.markings,
      //         });

      //         this.props.updateCommentLength(rightMarkings.markings, "307");
      //         console.log("rightMarkings.markings2", rightMarkings);
      //       })
      //       .catch((err) => console.error(err));
      //   }
      // );
    } catch (error) {
      console.log(error);
    }
  }

  async editMarkComment(data) {
    try {
      const { projectId, right } = this.props;
      const token = JSON.parse(localStorage.getItem("client-authToken"));
      const responseObject = await fetch();
      //   const responseObject = await fetch(
      //     `${apis.server}/files/update-mark/${data.projectId}/${data.fileId}`,
      //     {
      //       method: "POST",
      //       headers: {
      //         Authorization: `Bearer ${token}`,
      //         "Content-Type": "application/json",
      //       },
      //       body: JSON.stringify(data),
      //     }
      //   );
      const { status } = responseObject;
      if (status === 200) {
        this.setState({ isLoading: true }, () => {
          getImageMarkings({ projectId, imageId: right._id })
            .then((rightMarkings) => {
              rightMarkings.markings.map((e) => {
                e.markings.toggleOn = true;
              });

              this.setState({
                rightMarkings: rightMarkings.markings,
              });

              this.props.updateCommentLength(rightMarkings.markings, "350");
              // console.log("rightMarkings.markings3", rightMarkings);
            })
            .catch((err) => console.error(err));
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  commentSubmitHandler({ mainReason, subReason, error }) {
    const { currentMark, currentImageDim } = this.state;
    const { markColor } = this.props;
    if (this.props.restrictComments)
      return alert("you have to reject image to add comments.");
    if (currentMark) {
      let id = `mark${Date.now()}`;
      this.setState(
        {
          currentMark: {
            ...currentMark,
            error: error,
            rejectionReasonMain: mainReason,
            specificReason: subReason,
            id: id,
            new: false,
            color: markColor,
          },
        },
        () => {
          this.sendMark(this.state.currentMark);
        }
      );
    } else {
      this.setState(
        {
          currentMark: {
            ...currentMark,
            ...currentImageDim,
            error: error,
            rejectionReasonMain: mainReason,
            specificReason: subReason,
            new: true,
            color: markColor,
          },
        },
        () => {
          this.sendMark(this.state.currentMark);
        }
      );
    }
  }

  toggleComment(mode = false) {
    if (mode === true) {
      this.setState({
        addingQuote: mode,
        commentOn: mode,
      });
    } else {
      this.setState({
        commentOn: false,
        addingQuote: false,
        currentMark: undefined,
        freeHandDrawingArr: [],
      });
    }
  }

  setSelected(commentId) {
    this.setState({
      selected: commentId,
    });
  }

  updateMark(data) {
    // const { right, projectId } = this.props;
    const { error, deleted, markId, hide } = data;
    // const fileId = right._id;
    const { rightMarkings } = this.state;
    // if (!markId) return;

    // if (error) {
    //   this.editMarkComment({
    //     projectId,
    //     fileId,
    //     markId,
    //     error,
    //   });
    // } else if (deleted) {
    //   this.editMarkComment({
    //     projectId,
    //     fileId,
    //     markId,
    //     deleted,
    //   });
    // } else
    if (hide) {
      const rightMarkingsCopy = JSON.parse(JSON.stringify(rightMarkings));

      const index = rightMarkingsCopy.findIndex((e) => {
        return e?._id == markId;
      });
      if (index != -1) {
        rightMarkingsCopy[index].toggleOn = !rightMarkingsCopy[index]?.toggleOn;
      }

      this.setState({ rightMarkings: rightMarkingsCopy });
      this.props.updateCommentLength(rightMarkingsCopy);
    } else {
      this.props.updateCommentLength(data);
    }
  }

  render() {
    const { left, right, leftMarkings, rangeValue } = this.state;
    const props = this.props;
    const state = this.state;
    // console.log("props in canvasinstance", this.state.currentMark);

    return (
      <div className="canvas-instance-root">
        <div className="canvas-section" id={`canvasSection`}>
          {/* {!left.shouldNotRetrieve && ( */}
          <div className="preview-left">
            <CanvasActions
              gridData={this.state.leftGridData}
              updateGrid={this.updateLeftGridData.bind(this)}
              setFitToView={this.setFitToView.bind(this)}
              side="left"
              fitToView={state.fitToView?.left}
            />
            <div
              className="canvasComponent-container"
              id={`previewLeft${props.id}`}
              key={left.fileIndex}
            >
              <LegacyCanvasComponent
                key={left.fileIndex}
                image={left ?? null}
                canvasId={`previewCanvasLeft${props.id}`}
                canvasContainerId={`previewLeft${props.id}`}
                markings={leftMarkings} //props.originalFile ? props.originalFile.comments :
                gridData={state.leftGridData}
                markTool={state.markTool}
                setFreeHandDrawingArr={this.setFreeHandDrawingArr.bind(this)}
                setCurrentMark={this.setCurrentMark.bind(this)}
                canvasSectionId={`canvasSection${props.id}`}
                fitToView={state.fitToView?.left}
                side="left"
                setFitToView={this.setFitToView.bind(this)}
                ignoreCommentCheck={true}
                drawBorderOnImage={true}

              />
            </div>
          </div>
          {/* )} */}
          <div className="preview-right">
            <CanvasActions
              gridData={this.state.rightGridData}
              updateGrid={this.updateRightGridData.bind(this)}
              setFitToView={this.setFitToView.bind(this)}
              side="right"
              fitToView={state.fitToView?.right}
            />
            <div
              className="canvasComponent-container"
              id={`previewRight${props.id}`}
              key={this.state.right}
            >
              <LegacyCanvasComponent
                image={right ?? null}
                canvasId={`previewCanvasRight${props.id}`}
                canvasContainerId={`previewRight${props.id}`}
                markings={state.rightMarkings} // props.markings
                gridData={state.rightGridData}
                markTool={state.markTool}
                setFreeHandDrawingArr={this.setFreeHandDrawingArr.bind(this)}
                setCurrentMark={this.setCurrentMark.bind(this)}
                addingQuote={state.addingQuote}
                freeHandDrawingArr={state.freeHandDrawingArr}
                currentMark={state.currentMark}
                markColor={props.markColor}
                setCurrentImageDim={this.setCurrentImageDim.bind(this)}
                selected={state.selected}
                canvasSectionId={`canvasSection${props.id}`}
                side="right"
                fitToView={state.fitToView?.right}
                setFitToView={this.setFitToView.bind(this)}
                drawBorderOnImage={true}
              />
            </div>
          </div>
        </div>
        <div className="divtest1">
          <div
            className="divtest2"
            draggable="true"
            onDrag={(e) => this.dragDiv(e, 1)}
          >
            <hr
              style={{
                width: "80vw",
                position: "relative",
                left: "-80vw",
                top: "7px",
                border: "1px solid green",
              }}
            />
          </div>

          <div
            className="divtest3"
            draggable="true"
            onDrag={(e) => this.dragDiv(e, 2)}
          >
            <hr
              style={{
                width: "80vw",
                position: "relative",
                left: "-80vw",
                top: "7px",
                border: "1px solid red",
              }}
            />
          </div>

          <div
            className="divtest4"
            draggable="true"
            onDrag={(e) => this.dragDiv(e, 3)}
          >
            <hr
              style={{
                width: "80vw",
                position: "relative",
                left: "-80vw",
                top: "7px",
                border: "1px solid blue",
              }}
            />
          </div>
        </div>
        {props.showAnnotations ? (
          <div className="annotation-tools">
            <AnnotationTools
              markTool={state.markTool}
              setMarkTool={this.setMarkTool.bind(this)}
              commentSubmitHandler={this.commentSubmitHandler.bind(this)}
              commentOn={state.commentOn}
              markColor={props.markColor}
              changeMarkColor={props.changeMarkColor}
              toggleComment={this.toggleComment.bind(this)}
              markings={state.rightMarkings} // props.markings
              setSelected={this.setSelected.bind(this)}
              updateMark={this.updateMark.bind(this)}
              addEvents={props.addEvents}
              removeEvents={props.removeEvents}
              currentPath={props.currentPath}
              skuId={props.right.skuId}
              updateCommentLength={props.updateCommentLength}
              commentRef={this.props.commentRef}
              fileId={this.props.projectId}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default InstanceLegacy;
